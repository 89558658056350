import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Profile from "../components/Profile"
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata
        title="About - Nick Partridge"
        description="Stuff about me."
      />
      <div className="md:flex">
        <aside className="p-6 md:p-8 main__aside">
          <Profile />
        </aside>
        <div className="main__main md:px-12 p-6 md:py-8"><p>G'day! My name is Nick, I'm an aussie guy living in Andorra, a tiny little country in-between Spain and France. I like to use writing as a way to concretise concepts and ideas that I'm interested in and/or learning about - which means that I'm at best an enthusiastic amateur on any given subject that I attempt to write about. So, you know, keep that in mind :)</p><br /><p>You'll find stuff on Data Science, travel, programming, language learning, books, and other such things. I mean, eventually you'll find all that, if I pick up the pace a bit.</p></div>
      </div>
    </Layout>
  )
}

export default IndexPage
