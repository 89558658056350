import React from "react"
import { Link } from "gatsby"
import SelfieShot from "../images/profile.png"
export default function Profile() {
  const path = typeof window !== "undefined" ? window.location.pathname : ""
  return (
    <>
      <Link to="/">
        <img
          src={SelfieShot}
          alt="placeholder"
          className="rounded-full"
          height="75"
          width="75"
        />
      </Link>
      <h1 className="font-bold text-lg my-6">Nick Partridge</h1>
      {/* <p className="mb-8 text-gray-700">
           Aussie guy, living in Europe.
          </p> */}
      <ul className="mb-8">
        <li className="mb-3">
          <Link
            to="/"
            className={`hover:text-blue-500 hover:underline ${
              path === "/" || path === "" ? "underline" : ""
            }`}
          >
            Articles
          </Link>
        </li>
        <li className="mb-3">
          <Link
            to="/about-me"
            className={`hover:text-blue-500 hover:underline ${
              path === "/about-me" ? "underline" : ""
            }`}
          >
            About me
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className={`hover:text-blue-500 hover:underline ${
              path === "/contact" ? "underline" : ""
            }`}
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* <span className="text-gray-500 text-sm mt-4 block">&copy; All rights reserved.</span> */}
    </>
  )
}
